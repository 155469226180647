<template>
  <div
    ref="echart"
    v-resize="onResize"
    style="width: 1200px; height: inherit"
  />
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import ApiService from '../../../services/api.service';
import { createSeriesOption } from './lib/transformer';

import { createSeriesQueryParams } from '../../../lib/query-creator';

export default {
  components: {

  },
  props: ['dataset'],
  data() {
    return {
      chart: null,
    };
  },
  computed: {

  },
  mounted() {
    this.init();
    this.loadData();
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      // this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {

    },
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async loadData() {
      this.$store.commit('mSetLoading', true);
      if (this.dataset && this.dataset.table_name) {
        const appConfig = this.$store.state.appConfig;

        // console.log('this.dataset', this.dataset);
        const result = createSeriesQueryParams(this.dataset);
        // const sql = createSeriesQuery(this.dataset);
        const response = await ApiService.post('/raw_sql/', { q: result.sql });
        const params = {
          xColumn: result.joinColumn,
          // xColumn: 'Date',
          yColumn: 'Location',
          valueColumn: 'value',
          labelColumn: 'Location',
        };
        const data = response.data;
        const option = createSeriesOption(data, params);
        console.log('option', option);
        this.draw(option);
      }
      this.$store.commit('mSetLoading', false);
    },
  },
};

</script>
